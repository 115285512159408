import styled from 'styled-components';

const AppWrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  min-height: 100vh;

  .sticky-active {
    .navbar {
      background-color: ${(props) => props.theme.colors.white};
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);

      .logo {
        color: ${(props) => props.theme.colors.primaryText};

        .logoEmphasis {
          color: ${(props) => props.theme.colors.primary};
        }
      }

      ul {
        li {
          a {
            color: ${(props) => props.theme.colors.primaryText};
            transition: all 0.3s ease;
            &:hover {
              color: ${(props) => props.theme.colors.primary};
            }
          }
          &.is-current {
            a {
              color: ${(props) => props.theme.colors.primary};
            }
          }
        }
      }

      .navbar__menu {
        padding: 1rem 2rem;
      }

      .menuToggle {
        span,
        span:after,
        span:before {
          background-color: ${(props) => props.theme.colors.primaryText};
        }
      }
    }
  }
`;

export default AppWrapper;
