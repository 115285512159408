import { DefaultTheme } from 'styled-components';
import colors from './colors';

export enum BreakPoint {
  sm = '37em',
  md = '48em',
  lg = '62em',
  xl = '75em',
  xxl = '112.5em',
}

const breakpoints: any = [
  BreakPoint.sm,
  BreakPoint.md,
  BreakPoint.lg,
  BreakPoint.xl,
  BreakPoint.xxl,
];
// eslint-disable-next-line prefer-destructuring
[
  breakpoints.sm,
  breakpoints.md,
  breakpoints.lg,
  breakpoints.xl,
  breakpoints.xxl,
] = breakpoints;

const theme: DefaultTheme = {
  breakpoints,
  colors,
  space: [
    0,
    5,
    8,
    '1rem',
    '1.2rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '4rem',
    '5rem',
  ],
  fontSizes: ['1.4rem', '1.6rem', '2rem', '2.6rem'],
  fontWeights: [400, 500, 600, 700],
  textStyles: {
    primaryHeading: {
      fontWeight: 700,
      fontSize: '3rem',
    },
    secondaryHeading: {
      fontWeight: 700,
      fontSize: '2.4rem',
    },
    tertiaryHeading: {
      fontWeight: 700,
      fontSize: '1.4rem',
    },
    fourthHeading: {
      fontWeight: 700,
      fontSize: '1.2rem',
    },
    displayLarge: {
      fontWeight: 500,
      fontSize: '1.8rem',
    },
    displayMedium: {
      fontWeight: 500,
      fontSize: '1.6rem',
    },
    displaySmall: {
      fontWeight: 500,
      fontSize: '1.4rem',
    },
  },
  colorStyles: {
    primary: {
      color: colors.primary,
    },
    primaryText: {
      color: colors.primaryText,
    },
  },
};

export default theme;
