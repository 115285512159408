import React from 'react';
import styled from 'styled-components';
import Text from '../Text/Text';

const CTAWrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 44px;
  min-width: 48px;
  border-radius: 25px;
  border: 0px solid transparent;
  transition: all 0.3s ease;

  font-family: inherit;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};
  text-transform: capitalize;
  cursor: pointer;

  & > *:not(:last-child) {
    margin-right: 4px;
  }

  &:focus {
    outline: none;
  }
`;

interface CTAProps extends React.ComponentPropsWithRef<'button'> {
  message: string;
}

const CTA: React.FC<CTAProps> = ({ message, ...rest }) => (
  <CTAWrapper {...rest}>
    <Text textStyle="tertiaryHeading">{message}</Text>
  </CTAWrapper>
);

export default CTA;
