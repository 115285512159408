import styled, { css } from 'styled-components';

export interface ContainerProps {
  fluid?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  ${(props) =>
    props.fluid &&
    css`
      width: 100%;
      max-width: none !important;
    `};

  @media (min-width: 576px) {
    max-width: 540px;
    width: 100%;
  }

  @media (min-width: 768px) {
    max-width: 750px;
    width: 100%;
  }
  @media (min-width: 992px) {
    max-width: 970px;
    width: 100%;
  }
  @media (min-width: 1220px) {
    max-width: 1170px;
    width: 100%;
  }

  @media (min-width: 1600px) {
    max-width: 1540px;
    width: 100%;
  }
`;

export default Container;
