import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';

import theme from '@/theme/theme';
import ResetCSS from '@/assets/css/reset';
import Navbar from '@/containers/Navbar/Navbar';
import AppWrapper from '@/containers/AppWrapper';
import Footer from '@/components/Footer/Footer';

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <ResetCSS />
    <AppWrapper>
      <Sticky top={0} innerZ={1000} activeClass="sticky-active">
        <Navbar />
      </Sticky>
      {children}
      <Footer />
    </AppWrapper>
  </ThemeProvider>
);

export default Layout;
