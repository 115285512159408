import { useEffect, useMemo, useState } from 'react';
import { useLocation } from '@reach/router';

const ACTIVE_CLASSNAME = 'active';
const THRESHOLD_RATIO = 0.3;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const onScrollUpdateBuilder =
  (activeClassName: string) => (elem: Element, intersectionRatio: number) => {
    // eslint-disable-next-line react/destructuring-assignment
    const id = elem.getAttribute('id');
    const menuItem = document.querySelector(`[data-spy-id="${id}"]`);

    if (menuItem == null) {
      return null;
    }

    if (intersectionRatio > 0) {
      menuItem.classList.add(activeClassName);
    } else if (menuItem.classList.contains(activeClassName)) {
      menuItem.classList.remove(activeClassName);
    }

    return menuItem;
  };

export interface ScrollSpyProps {
  threshold?: number;
  activeClassName?: string;
}

// This function will listen when the observed section reach the viewport
// The `onScrollUpdate` function aims at activate a class on a section when possible

export const ScrollSpy: React.FC<ScrollSpyProps> = ({
  threshold,
  activeClassName,
}) => {
  const [windowHeight, setWindowHeight] = useState<number>();
  const onScrollUpdate = useMemo(
    () => onScrollUpdateBuilder(activeClassName),
    [activeClassName],
  );

  const { pathname } = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowHeight !== undefined) {
      const spies = document.querySelectorAll('[data-spy]');

      if (spies.length === 0) {
        return noop;
      }

      const markerHeight = Math.floor(windowHeight * threshold);
      const marginStart = windowHeight - (markerHeight + 1);
      const marginEnd = markerHeight;
      const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          entries.forEach(({ target, intersectionRatio }) => {
            onScrollUpdate(target, intersectionRatio);
          });
        },
        {
          rootMargin: `-${marginStart}px 0px -${marginEnd}px 0px`,
          threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        },
      );
      spies.forEach((spy) => observer.observe(spy));

      return () => {
        spies.forEach((spy) => observer.unobserve(spy));
      };
    }

    return noop;
  }, [onScrollUpdate, windowHeight, pathname]);

  return null;
};

ScrollSpy.defaultProps = {
  threshold: THRESHOLD_RATIO,
  activeClassName: ACTIVE_CLASSNAME,
};
