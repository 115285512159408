import { createGlobalStyle } from 'styled-components';
import { BreakPoint } from '@/theme/theme';
import colors from '@/theme/colors';

const ResetCSS = createGlobalStyle`
  *, 
  *::after, /* Because universal selectors does not apply to pseudo elements */
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *:focus {
    outline: none;
  }

  html {
    height: 100%;
    font-size: 62.5%;

    @media (max-width: ${BreakPoint.lg}) {
      font-size: 50%;
    }

    @media (max-width: ${BreakPoint.xl}) {
      font-size: 56.25%;
    }

    @media (min-width: ${BreakPoint.xxl}) {
      font-size: 70%;
    }
  }

  body {
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    font-family: 'Raleway', 'sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${colors.primaryText};
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  a:link, a:visited, a:hover {
    color: inherit;
    text-decoration: none;
  }
`;

export default ResetCSS;
